import React from "react";
import styles from "./style.module.scss";
import Tempo from "../../components/Tempo";
import Rythm from "../../components/Rythm";
import Beat from "../../components/Beat";
import Sound from "../../components/Sounds";
import { play, setTempo } from "../../script/metronome";
import classNames from "classnames";
import Footer from "../../components/Footer";

export default class Metronome extends React.Component {
  state = {
    tempo: 120,
    test: 2
  };

  handleStart = () => {
    play(this.handleTest);
  };

  handleTest = () => {
    if (this.state.test + 1 > 5) {
      return this.setState({ test: 2 });
    }
    this.setState({ test: this.state.test + 1 });
  };

  handleTempo = e => {
    this.setState({ tempo: parseInt(e.target.value) }, () =>
      setTempo(this.state.tempo)
    );
  };

  changeTempo = val => {
    this.setState({ tempo: val }, () => setTempo(this.state.tempo));
  };

  tempoUp = () => {
    if (this.state.tempo === 360) {
      return;
    }
    this.setState({ tempo: this.state.tempo + 1 }, () =>
      setTempo(this.state.tempo)
    );
  };

  tempoDown = () => {
    if (this.state.tempo === 0) {
      return;
    }
    this.setState({ tempo: this.state.tempo - 1 }, () =>
      setTempo(this.state.tempo)
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <img src={require("../../static/images/title.png")} alt="title" />
        </div>
        <div className={styles.controls}>
          <div className={styles["ctrl-ct"]}>
            <div className={styles.sldContainer}>
              <input
                type="range"
                min="0"
                max="360"
                className={styles.slider}
                onChange={this.handleTempo}
                value={this.state.tempo}
              />
            </div>
            <div className={styles.dotsCont}>
              <div
                className={classNames(styles.dot, {
                  [styles.dot__active]:
                    this.state.test % 2 === 0 &&
                    this.state.test % 3 !== 0 &&
                    this.state.test % 4 !== 0 &&
                    this.state.test % 5 !== 0
                })}
              />
              <div
                className={classNames(styles.dot, {
                  [styles.dot__active]:
                    this.state.test % 3 === 0 && this.state.test % 4 !== 0
                })}
              />
              <div
                className={classNames(styles.dot, {
                  [styles.dot__active]: this.state.test % 4 === 0
                })}
              />
              <div
                className={classNames(styles.dot, {
                  [styles.dot__active]: this.state.test % 5 === 0
                })}
              />
            </div>
            <div>
              <div className={styles.off} onClick={this.handleStart} />
            </div>
          </div>
          <div className={styles["ctrl-ct"]}>
            <Tempo
              tempo={this.state.tempo}
              tempoUp={this.tempoUp}
              tempoDown={this.tempoDown}
              changeTempo={this.changeTempo}
            />
            <Rythm />
            <Beat />
            <Sound />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
