import React from "react";
import styles from "./style.module.scss";
import { setMeter } from "../../script/metronome";
import classNames from "classnames";

export default class Beat extends React.Component {
  state = {
    cur: "0"
  };

  handleBeat = e => {
    const val = parseInt(e.target.getAttribute("no"));
    this.setState({ cur: e.target.getAttribute("no") });
    setMeter(val);
  };

  render() {
    return (
      <div className={styles.beat}>
        <div>
          <img src={require("../../static/images/beat.png")} alt="beat" />
        </div>
        <div className={styles.ctrl}>
          <div className={styles.ctrl__top}>
            <div
              className={classNames(styles.b0, {
                [styles.b0__active]: this.state.cur === "0"
              })}
              no="0"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b1, {
                [styles.b1__active]: this.state.cur === "1"
              })}
              no="1"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b2, {
                [styles.b2__active]: this.state.cur === "2"
              })}
              no="2"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b3, {
                [styles.b3__active]: this.state.cur === "3"
              })}
              no="3"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b4, {
                [styles.b4__active]: this.state.cur === "4"
              })}
              no="4"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b5, {
                [styles.b5__active]: this.state.cur === "5"
              })}
              no="5"
              onClick={this.handleBeat}
            />
          </div>
          <div className={styles.ctrl__bottom}>
            <div
              className={classNames(styles.b6, {
                [styles.b6__active]: this.state.cur === "6"
              })}
              no="6"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b7, {
                [styles.b7__active]: this.state.cur === "7"
              })}
              no="7"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b8, {
                [styles.b8__active]: this.state.cur === "8"
              })}
              no="8"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b9, {
                [styles.b9__active]: this.state.cur === "9"
              })}
              no="9"
              onClick={this.handleBeat}
            />
            <div
              className={classNames(styles.b10, {
                [styles.b10__active]: this.state.cur === "10"
              })}
              no="10"
              onClick={this.handleBeat}
            />
          </div>
        </div>
      </div>
    );
  }
}
