import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import {setInstr} from '../../script/metronome'
export default class Sound extends React.Component {
  state = {
    cur: "pc"
  };

  handleSound = e => {
    this.setState({ cur: e.target.getAttribute("name") });
    setInstr(e.target.getAttribute("name"))
  };



  render() {
    return (
      <div className={styles.rythm}>
        <div>
          <img src={require("../../static/images/sound.png")} alt="sound" />
        </div>
        <div className={styles.ctrl}>
          <div
            className={classNames(styles.s1, {
              [styles.s1__active]: this.state.cur === "metro"
            })}
            name="metro"
            onClick={this.handleSound}
          />
          <div
            className={classNames(styles.s2, {
              [styles.s2__active]: this.state.cur === "instr"
            })}
            name="wood"
            onClick={this.handleSound}
          />
          <div
            className={classNames(styles.s3, {
              [styles.s3__active]: this.state.cur === "pc"
            })}
            name="pc"
            onClick={this.handleSound}
          />
        </div>
      </div>
    );
  }
}
