import React from "react";
import "./App.css";
import { setAccentVolume, setMeter } from "./script/metronome";
import myWorker from "./components/test.worker.js";
import Metronome from "./pages/Metronome";

class App extends React.Component {
  state = {
    counter: 0,
    accent: 0,
    beat: 4,
    eight: 0,
    note: ""
  };

  componentDidMount() {
    const worker = new myWorker();
  }

  handleAccent = e => {
    this.setState({ accent: e.target.value });
    setAccentVolume(e.target.value);
  };

  handleBeat = e => {
    this.setState({ beat: e.target.value });
    setMeter(e.target.value);
  };

  render() {
    return <Metronome />;
  }
}

export default App;

