import React from "react";
import styles from "./style.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className={styles.footer}>
        <img src={require("../../static/icons/logo.png")} alt="logo" />
      </div>
    );
  }
}

export default Footer;
