import React from "react";
import styles from "./style.module.scss";
import { setNote } from "../../script/metronome";
import classNames from "classnames";

export default class Rythm extends React.Component {
  state = {
    cur: "normal"
  };

  handleNote = e => {
    const val = e.target.getAttribute("name");
    this.setState({ cur: e.target.getAttribute("name") });
    setNote(val);
  };

  render() {
    return (
      <div className={styles.rythm}>
        <div>
          <img src={require("../../static/images/rythm.png")} alt="rythm" />
        </div>
        <div className={styles.ctrl}>
          <div
            className={classNames(styles.n1, {
              [styles.n1__active]: this.state.cur === "normal"
            })}
            name="normal"
            onClick={this.handleNote}
          />
          <div
            className={classNames(styles.n2, {
              [styles.n2__active]: this.state.cur === "eighth"
            })}
            name="eighth"
            onClick={this.handleNote}
          />
          <div
            className={classNames(styles.n3, {
              [styles.n3__active]: this.state.cur === "triplet"
            })}
            name="triplet"
            onClick={this.handleNote}
          />
          <div
            className={classNames(styles.n4, {
              [styles.n4__active]: this.state.cur === "sixteenth"
            })}
            name="sixteenth"
            onClick={this.handleNote}
          />
        </div>
      </div>
    );
  }
}
