import myWorker from "../components/test.worker";
import BufferLoader from "./bufferLoader";

var audioContext = null;
var isPlaying = false;
var currentTwelveletNote;
var tempo = 120.0;
var meter = 0;
var masterVolume = 0.5;
var accentVolume = 1;
var quarterVolume = 1;
var eighthVolume = 0;
var sixteenthVolume = 0;
var tripletVolume = 0;
var lookahead = 25.0;
var scheduleAheadTime = 0.1;
var nextNoteTime = 0.0;
var noteLength = 0.05;
var notesInQueue = [];
var timerWorker = null;
var instr = null;
var bufferLoader;

var woodClick;
var woodClick2;
var metroClick;
var metroClick2;

export function setAccentVolume(val) {
  accentVolume = val;
}

export function setMeter(val) {
  meter = val;
}

export function setNote(val) {
  reset();
  if (val === "eighth") {
    eighthVolume = 0.5;
  } else if (val === "sixteenth") {
    eighthVolume = 0.3;
    sixteenthVolume = 0.3;
  } else if (val === "triplet") {
    tripletVolume = 0.5;
  } else {
    reset();
  }
}

let mapper = {};

export function setTempo(val) {
  tempo = val;
}

export function setInstr(val) {
  instr = val;
}

function reset() {
  eighthVolume = 0;
  sixteenthVolume = 0;
  tripletVolume = 0;
}

function maxBeats() {
  var beats = meter * 12;
  return beats;
}

function nextTwelvelet() {
  var secondsPerBeat = 60.0 / tempo;
  nextNoteTime += 0.08333 * secondsPerBeat;
  currentTwelveletNote++;

  if (currentTwelveletNote == maxBeats()) {
    currentTwelveletNote = 0;
  }
}

function calcVolume(beatVolume) {
  return beatVolume * masterVolume;
}

function scheduleNote(beatNumber, time) {
  notesInQueue.push({ note: beatNumber, time: time });

  var osc = audioContext.createOscillator();
  var gainNode = audioContext.createGain();

  osc.connect(gainNode);
  osc.connect(gainNode);
  gainNode.connect(audioContext.destination);

  if (beatNumber % maxBeats() === 0) {
    if (instr === "wood") {
      gainNode.gain.value = calcVolume(0);
      playSound(woodClick2);
      mapper.test();
    } else if (instr === "metro") {
      gainNode.gain.value = calcVolume(0);
      playSound(metroClick2);
      mapper.test();
    } else {
      osc.frequency.value = 880.0;
      gainNode.gain.value = calcVolume(accentVolume);
      mapper.test();
    }
  } else if (beatNumber % 12 === 0) {
    if (instr === "wood") {
      gainNode.gain.value = calcVolume(0);
      playSound(woodClick);
      mapper.test();
    } else if (instr === "metro") {
      gainNode.gain.value = calcVolume(0);
      playSound(metroClick);
      mapper.test();
    } else {
      osc.frequency.value = 440.0;
      gainNode.gain.value = calcVolume(quarterVolume);
      mapper.test();
    }
  } else if (beatNumber % 6 === 0) {
    if (instr === "wood") {
      gainNode.gain.value = calcVolume(0);
      if (eighthVolume === 0) {
        return;
      }
      playSound(woodClick);
    } else if (instr === "metro") {
      gainNode.gain.value = calcVolume(0);
      if (eighthVolume === 0) {
        return;
      }
      playSound(metroClick);
    } else {
      osc.frequency.value = 440.0;
      gainNode.gain.value = calcVolume(eighthVolume);
    }
  } else if (beatNumber % 4 === 0) {
    if (instr === "wood") {
      if (tripletVolume === 0) {
        return;
      }
      gainNode.gain.value = calcVolume(0);
      playSound(woodClick);
    } else if (instr === "metro") {
      if (tripletVolume === 0) {
        return;
      }
      gainNode.gain.value = calcVolume(0);
      playSound(metroClick);
    } else {
      osc.frequency.value = 440.0;
      gainNode.gain.value = calcVolume(tripletVolume);
    }
  } else if (beatNumber % 3 === 0) {
    if (instr === "wood") {
      if (sixteenthVolume === 0) {
        return;
      }
      gainNode.gain.value = calcVolume(0);
      playSound(woodClick);
    } else if (instr === "metro") {
      if (sixteenthVolume === 0) {
        return;
      }
      gainNode.gain.value = calcVolume(0);
      playSound(metroClick);
    } else {
      osc.frequency.value = 440.0;
      gainNode.gain.value = calcVolume(sixteenthVolume);
    }
  } else {
    gainNode.gain.value = 0;
  }

  osc.start(time);
  osc.stop(time + noteLength);
}

function playSound(buffer) {
  var source = audioContext.createBufferSource();
  source.buffer = buffer;
  source.connect(audioContext.destination);
  if (!source.start) source.start = source.noteOn;
  source.start(0);
}

function scheduler() {
  while (nextNoteTime < audioContext.currentTime + scheduleAheadTime) {
    scheduleNote(currentTwelveletNote, nextNoteTime);
    nextTwelvelet();
  }
}

export function play(callback) {
  isPlaying = !isPlaying;
  mapper.test = callback;

  if (isPlaying) {
    currentTwelveletNote = 0;
    nextNoteTime = audioContext.currentTime;
    timerWorker.postMessage("start");
  } else {
    timerWorker.postMessage("stop");
  }
}

function init() {
  console.log("init");
  audioContext = new AudioContext();
  timerWorker = new myWorker();

  bufferLoader = new BufferLoader(
    audioContext,
    [
      "./sounds/woodblock_normal.wav",
      "./sounds/woodblock_accent.wav",
      "./sounds/retoro_normal.wav",
      "./sounds/retoro_accent.wav"
    ],
    bufferList => {
      woodClick = bufferList[0];
      woodClick2 = bufferList[1];
      metroClick = bufferList[2];
      metroClick2 = bufferList[3];
    }
  );

  bufferLoader.load();

  timerWorker.onmessage = function(e) {
    if (e.data == "tick") {
      scheduler();
    } else {
      console.log("message: " + e.data);
    }
  };

  timerWorker.postMessage({ interval: lookahead });
}

window.addEventListener("load", init);
