import React from "react";
import styles from "./style.module.scss";

export default class Tempo extends React.Component {
  handleTempo = e => {
    this.props.changeTempo(e.target.value);
  };

  render() {
    return (
      <div>
        <div>
          <img src={require("../../static/images/tempo.png")} alt="title" />
        </div>
        <div className={styles.ctrl}>
          <div className={styles.d} onClick={this.props.tempoDown} />
          <div>
            <input
              className={styles.scr}
              value={this.props.tempo}
              onChange={this.handleTempo}
            />
          </div>
          <div className={styles.u} onClick={this.props.tempoUp} />
        </div>
      </div>
    );
  }
}
